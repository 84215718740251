<template>
  <div class="auditlog-wrapper">
    <Breadcrumbs />
    <v-row
      class="content-wrapper pa-0"
      style="flex-wrap: nowrap;overflow:auto;"
    >
      <v-col cols="3" style="min-width:280px;">
        <v-card outlined class="rounded-sm dense" style="height:738px">
          <v-card-title
            class="pa-3 font-weight-bold"
            style="font-size: .9rem; color: rgba(0, 0, 0, 0.87)"
            >账户</v-card-title
          >
          <v-divider></v-divider>
          <v-card-actions class="pa-3 pb-0">
            <v-text-field
              v-model="searchName"
              @input="searchCurName(searchName, (accountsCurPage = 1))"
              @keyup.enter="searchCurName(searchName, (accountsCurPage = 1))"
              @click:append="searchCurName(searchName, (accountsCurPage = 1))"
              append-icon="mdi-magnify"
              placeholder="请输入账户名称进行搜索"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-card-actions>
          <v-card-text class="pa-3 pt-0">
            <v-list dense>
              <v-list-item-group v-model="selectedItem" color="primary">
                <v-list-item
                  v-for="item in accounts"
                  :key="item.id"
                  @click="loginNameSel(item.id)"
                  class="pl-2 pt-3"
                  style="border-bottom: 1px solid #ddd; min-height: auto"
                >
                  <v-list-item-content style="padding-bottom: 0">
                    <span v-if="item.login_name || item.fullname">
                      {{ item.login_name || item.fullname }}&nbsp;&nbsp;({{
                        item.external_id
                      }})
                    </span>
                    <span v-else>{{ item.external_id }}</span>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
            <v-pagination
              style="position:absolute;bottom:16px;right:10px;width:100%"
              v-if="Math.ceil(accountsTotal / limit) > 1"
              v-model="accountsCurPage"
              :length="Math.ceil(accountsTotal / limit)"
              total-visible="4"
              @input="accountsPageChange(accountsCurPage, limit)"
            ></v-pagination>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card outlined class="rounded-sm" style="height:738px">
          <v-card-text>
            <v-app-bar
              elevation="0"
              dense
              flat
              style="background: none; margin-left: -12px"
            >
              <v-text-field
                readonly
                v-model="loginName"
                :append-icon="loginName ? 'mdi-close-circle' : null"
                @click:append="clearLoginName"
                label="账号登录名"
                placeholder="请在左侧搜索帐户"
                hide-details="auto"
                class="mr-4 loginname"
                style="width: 110px; flex: none; background: none"
              ></v-text-field>
              <v-select
                :items="authMethods"
                item-text="name"
                item-value="id"
                v-model="login_method"
                label="认证方式"
                placeholder="请选择认证方式"
                hide-details="auto"
                dense
                class="mr-4"
                @change="authModeSel(login_method)"
                style="width: 136px; flex: none; background: none"
              >
                <template #item="{item}">
                  <span>{{ item.name }}</span>
                  <span
                    :style="{
                      color: item.archived ? '#9195A3' : '',
                      paddingLeft: item.archived ? '4px' : ''
                    }"
                  >
                    {{ item.archived ? "已删除" : "" }}
                  </span>
                </template>
              </v-select>
              <v-select
                :items="ENABLE_STATUS"
                v-model="enableStatus"
                hide-details
                label="操作结果"
                dense
                class="mr-4"
                style="width: 90px; flex: none; background: none"
                placeholder="请选择操作结果"
                @change="searchEnabled(enableStatus)"
              ></v-select>
              <v-text-field
                v-model="clientIp"
                :append-icon="clientIp ? 'mdi-close-circle' : null"
                @click:append="clearClientIp"
                @input="searchClientIp(clientIp)"
                label="客户端IP"
                placeholder="请选择客户端ip"
                hide-details="auto"
                class="mr-4 loginname"
                style="width: 140px; flex: none; background: none"
              ></v-text-field>
              <v-menu
                class="datepicker-menu"
                ref="menu"
                v-model="show_start_datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-4 mt-5 loginname"
                    style="width: 130px; flex: none; background: none"
                    v-model="start_time"
                    :append-icon="start_time ? 'mdi-close-circle' : ''"
                    @click:append="clearStartTime"
                    placeholder="开始时间"
                    label="开始时间"
                    readonly
                    rows="1"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_time"
                  :allowed-dates="allowedStartDate"
                  no-title
                  scrollable
                  :show-current="false"
                  @input="show_start_datepicker = false"
                  :day-format="date => date.split('-')[2]"
                  locale="zh-cn"
                >
                </v-date-picker>
              </v-menu>
              <v-menu
                class="datepicker-menu"
                ref="menu"
                v-model="show_end_datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    class="mr-4 mt-5 loginname"
                    style="width: 130px; flex: none; background: none"
                    v-model="end_time"
                    :append-icon="end_time ? 'mdi-close-circle' : ''"
                    @click:append="clearEndTime"
                    placeholder="结束时间"
                    label="结束时间"
                    readonly
                    rows="1"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="end_time"
                  :allowed-dates="allowedEndDate"
                  no-title
                  scrollable
                  :show-current="false"
                  @input="show_end_datepicker = false"
                  :day-format="date => date.split('-')[2]"
                  locale="zh-cn"
                >
                </v-date-picker>
              </v-menu>
              <v-btn
                depressed
                color="primary"
                style="padding: 0 24px; height: 34px !important"
                @click.stop="
                  searchAccounts(
                    accountId,
                    sourceId,
                    enableIdx,
                    clientIp,
                    start_time,
                    end_time,
                    1,
                    10
                  )
                "
              >
                {{ $t("account.Search") }}
              </v-btn>
            </v-app-bar>
            <v-simple-table class="table-wrapper ml-0">
              <thead>
                <tr>
                  <th class="text-left pl-4">
                    {{ $t("audit_log.login.username") }}
                  </th>
                  <th class="text-left">
                    {{ $t("audit_log.login.authMode") }}
                  </th>
                  <th class="text-left">
                    {{ $t("audit_log.login.result") }}
                  </th>
                  <th class="text-left">
                    {{ $t("audit_log.login.IP") }}
                  </th>
                  <th class="text-left" style="width:144px">
                    {{ $t("audit_log.login.time") }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="logs.length > 0">
                <tr v-for="item in logs" :key="item.id" class="text-left pl-4">
                  <td v-if="item.identity" class="pl-4">
                    {{
                      item.identity.claims.login_name ||
                        item.identity.claims.fullname ||
                        item.identity.claims.nickname
                    }}
                    <span v-if="item.identity.name"
                      >({{ item.identity.name }})</span
                    >
                  </td>
                  <td v-if="item.identity">
                    {{ item.source_name }}
                  </td>
                  <td v-else>
                    {{ item.identity.claims.login_name || "-" }} ({{
                      item.source_name
                    }}：{{ item.identity.claims.nickname || "-" }})
                  </td>
                  <td>
                    {{ item.result_type === "ok" ? "操作成功" : "操作失败" }}
                    <span v-if="item.result_type === 'fail'">
                      ，{{ failResult[item.result] }}
                    </span>
                  </td>
                  <td>{{ item.client_ip }}</td>
                  <td>
                    {{ item.created.replace(/T/, " ") }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td
                    colspan="5"
                    style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                  >
                    暂无数据
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
            <v-pagination
              v-if="Math.ceil(totalPage / limit) > 1"
              v-model="curPage"
              :length="Math.ceil(totalPage / limit)"
              total-visible="7"
              @input="
                onPageChange(
                  accountId,
                  sourceId,
                  enableIdx,
                  clientIp,
                  start_time,
                  end_time,
                  curPage,
                  limit
                )
              "
            ></v-pagination>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { iso8601 } from "@/util/misc";
import Breadcrumbs from "@/components/Breadcrumbs";
import ConfirmBox from "@/components/ConfirmBox";

const ENABLE_STATUS_KEYS = ["", "ok", "fail"];

export default {
  name: "AuditLogLogin",
  data() {
    return {
      curPage: 1,
      limit: 10,
      totalPage: 0,
      logs: [],
      accounts: [],
      allAccounts: [],
      accountsCurPage: 1,
      accountsTotal: 0,
      drawer_status: [false],
      allAuthMethods: [],
      authMethods: [],
      login_method: null,
      show_start_datepicker: false,
      show_end_datepicker: false,
      ENABLE_STATUS: ["全部", "成功", "失败"],
      enableIdx: null,
      enableStatus: "",
      start_time: null,
      end_time: null,
      selectedItem: "",
      loginName: null,
      clientIp: null,
      searchName: "",
      accountId: null,
      sourceId: null,
      failResult: {
        InvalidCredentials: "密码错误",
        LoginNameNotRecognized: "帐户不存在"
      }
    };
  },
  async created() {
    await this.fetchLoginMethods();
    this.refreshAccounts();
    let querySourceId = this.$route.query.id;
    if (querySourceId) {
      this.sourceId = querySourceId;
      this.login_method = this.allAuthMethods.find(
        item => item.id === Number(querySourceId)
      ).name;
      this.searchAccounts(
        this.accountId,
        this.sourceId,
        this.enableIdx,
        this.clientIp,
        this.start_time,
        this.end_time,
        (this.curPage = 1),
        10
      );
    } else {
      await this.refreshList();
    }
  },
  methods: {
    // 操作结果
    searchEnabled(enableStatus) {
      let enableIdx = this.ENABLE_STATUS.findIndex(
        data => data === enableStatus
      );
      this.enableIdx = enableIdx;
      this.searchAccounts(
        this.accountId,
        this.sourceId,
        this.enableIdx,
        this.clientIp,
        this.start_time,
        this.end_time,
        (this.curPage = 1),
        10
      );
    },
    // 选择下拉框
    authModeSel(val) {
      let curId = this.allAuthMethods.find(item => item.id === val);
      this.sourceId = curId ? curId.id : null;
      this.searchAccounts(
        this.accountId,
        this.sourceId,
        this.enableIdx,
        this.clientIp,
        this.start_time,
        this.end_time,
        (this.curPage = 1),
        10
      );
    },
    // 认证方式下拉选
    fetchLoginMethods() {
      return this.$http
        .get(`api/source/list_all`)
        .delegateTo(api_request)
        .then(res => {
          let allAuthMethods = res.sources;
          this.allAuthMethods = allAuthMethods;
          this.authMethods = allAuthMethods;
          this.authMethods.unshift({ name: "全部" });
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    accountsPageChange(accountsCurPage, limit) {
      if (this.searchName.trim().length > 0) {
        this.searchCurName(this.searchName, accountsCurPage, limit);
      } else {
        this.refreshAccounts(accountsCurPage, limit);
      }
    },
    searchCurName(searchName, accountsCurPage = 0, limit = 10) {
      let offset = 0;
      if (accountsCurPage) {
        offset = (accountsCurPage - 1) * limit;
      }

      if (searchName.trim().length > 0) {
        // 调取左侧账户名称搜索api
        return this.$http
          .get(
            `api/audit/search_account?value=${searchName}&offset=${offset}&limit=${limit}`
          )
          .delegateTo(api_request)
          .then(res => {
            this.accounts = res.accounts;
            this.accountsTotal = res.total;
          })
          .delegateTo(this.$snackbar.delegateError);
      } else {
        this.refreshAccounts();
      }
    },
    refreshAccounts(accountsCurPage = 0, limit = 10) {
      let offset = 0;
      if (accountsCurPage) {
        offset = (accountsCurPage - 1) * limit;
      }
      // 调取左侧账户名称搜索api
      return this.$http
        .get(`api/audit/search_account?offset=${offset}&limit=${limit}`)
        .delegateTo(api_request)
        .then(res => {
          this.accounts = res.accounts;
          this.accountsTotal = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchClientIp(clientIp) {
      let pattern = /((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}/g;
      if (pattern.test(clientIp)) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      }
    },
    // 选择左侧账户列表项
    loginNameSel(id) {
      this.accountId = id;
      let curLoginItem = this.accounts.find(item => item.id === id);
      if (curLoginItem) {
        let curLoginName = curLoginItem.login_name;
        let curExternal_id = curLoginItem.external_id;
        curLoginName !== null
          ? (this.loginName = curLoginName)
          : (this.loginName = curExternal_id);
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          1,
          10
        );
      }
    },
    onPageChange(
      accountId,
      sourceId,
      enableIdx,
      clientIp,
      start_time,
      end_time,
      curPage,
      limit
    ) {
      this.searchAccounts(
        accountId,
        sourceId,
        enableIdx,
        clientIp,
        start_time,
        end_time,
        curPage,
        limit
      );
    },
    refreshList(curPage = 1, limit = 10) {
      return this.$http
        .get(
          `api/audit/logs/authentication?offset=${(curPage - 1) *
            limit}&limit=${limit}&need_count=true`
        )
        .delegateTo(api_request)
        .then(res => {
          this.logs = res.logs;
          this.totalPage = res.total;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 清空账户名称
    clearLoginName() {
      this.loginName = null;
      this.accountId = null;
      this.selectedItem = null;
      this.curPage = 1;
      if (
        this.sourceId != null ||
        this.enableIdx != null ||
        this.clientIp != null ||
        this.start_time != null ||
        this.end_time != null
      ) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空客户端IP
    clearClientIp() {
      this.clientIp = null;
      this.curPage = 1;
      if (
        this.accountId != null ||
        this.sourceId != null ||
        this.enableIdx != null ||
        this.start_time != null ||
        this.end_time != null
      ) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空开始时间
    clearStartTime() {
      this.start_time = null;
      this.curPage = 1;
      if (
        this.accountId != null ||
        this.enableIdx != null ||
        this.clientIp != null ||
        this.sourceId != null ||
        this.end_time != null
      ) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    // 清空结束时间
    clearEndTime() {
      this.end_time = null;
      this.curPage = 1;
      if (
        this.accountId != null ||
        this.enableIdx != null ||
        this.clientIp != null ||
        this.sourceId != null ||
        this.start_time != null
      ) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1),
          10
        );
      } else {
        this.refreshList();
      }
    },
    refreshAllAccounts() {
      return this.$http
        .get(`api/account/list`)
        .delegateTo(api_request)
        .then(res => {
          this.allAccounts = res.accounts;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    searchAccounts(
      accountId,
      sourceId,
      enableIdx,
      clientIp,
      start_time,
      end_time,
      curPage = 1,
      limit = 10
    ) {
      let result =
        ENABLE_STATUS_KEYS[enableIdx] === undefined
          ? ""
          : ENABLE_STATUS_KEYS[enableIdx];
      let need_count =
        accountId || sourceId || enableIdx || clientIp || start_time || end_time
          ? true
          : false;

      // 调取右侧table列表搜索api
      return this.$http
        .get(
          `api/audit/logs/authentication?account_id=${
            accountId ? accountId : ""
          }&source_id=${sourceId ? sourceId : ""}&result_type=${
            result === undefined ? "" : result
          }&client_ip=${clientIp ? clientIp : ""}&start_time=${
            start_time ? `${start_time} 00:00:00` : ""
          }&end_time=${
            end_time !== 1 && end_time != null ? `${end_time} 00:00:00` : ""
          }&offset=${(curPage - 1) *
            limit}&limit=${limit}&need_count=${need_count}`
        )
        .delegateTo(api_request)
        .then(res => {
          this.logs = res.logs;
          if (need_count) {
            this.totalPage = res.total;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    allowedStartDate(val) {
      if (this.end_time) {
        let cur = new Date(val).getTime();
        let end = new Date(this.end_time).getTime();

        return cur < end;
      }
      return true;
    },
    allowedEndDate(val) {
      if (this.start_time) {
        let cur = new Date(val).getTime();
        let end = new Date(this.start_time).getTime();
        return cur > end;
      }
      return true;
    }
  },
  watch: {
    start_time() {
      if (this.start_time) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1)
        );
      }
    },
    end_time() {
      if (this.end_time) {
        this.searchAccounts(
          this.accountId,
          this.sourceId,
          this.enableIdx,
          this.clientIp,
          this.start_time,
          this.end_time,
          (this.curPage = 1)
        );
      }
    }
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.breadcrumbs;
    },
    today() {
      return iso8601(new Date());
    }
  },
  components: {
    Breadcrumbs,
    ConfirmBox
  }
};
</script>

<style lang="less" scoped>
.auditlog-wrapper {
  width: 100%;
  .table-wrapper {
    min-height: 600px;
  }
  /deep/.v-pagination {
    justify-content: flex-end;
    width: 96%;
  }
  .v-list-item:hover {
    border-right: 2px solid #00b0ff;
  }
  .v-list-item--active {
    border-right: 2px solid #0091ea;
  }
}

/deep/.loginname .v-icon.v-icon {
  font-size: 16px;
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
